import React from "react";
import i18n from "../../../i18n/i18n";
import { withTranslation } from "react-i18next";

function Plan() {
	return (
		<section className="section">
			<div className="container py-6">
				<div className="mt-6">
					<h4 className="subtitle is-4">
						<p>{i18n.t("PlanBlock_CoursePriceTitle")}</p>
					</h4>
					<p class="is-size-5-tablet is-size-5-desktop mb-3">
						{i18n.t("PlanBlock_CoursePriceSubtitle")}
					</p>
					<div className="columns columns-wrap">
						<div className="column">
							<div className="orentation">
								<div className="orientation_title">
									{i18n.t("PlanBlock_TechOrdaGoTitle")}
								</div>
								<div className="orientation_text">
									<p>
										<ul className="mx-auto max-width-350px has-text-left my-4">
											<li className="has-text-weight-bold mb-2">
												- {i18n.t("PlanBlock_TechOrdaGoText1")}
											</li>
											<li className="has-text-weight-bold mb-2">
												- {i18n.t("PlanBlock_TechOrdaGoText2")}
											</li>
											<li className="has-text-weight-bold mb-2">
												- {i18n.t("PlanBlock_TechOrdaGoText3")}
											</li>
											<li className="has-text-weight-bold mb-2">
												- {i18n.t("PlanBlock_TechOrdaGoText4")}
											</li>
										</ul>
										<div className="is-align-items-center line-hor-75 has-background-black my-2"></div>
										{i18n.t("PlanBlock_TechOrdaGoTextF")}
										<br />
										<div className="max-width-350px mx-auto has-text-left has-text-weight-bold">
											{i18n.t("Price")}: 600 000 тг
										</div>
									</p>
								</div>
							</div>
						</div>
						<div className="column">
							<div className="orentation">
								<div className="orientation_title">
									{i18n.t("PlanBlock_TechOrdaSysTitle")}
								</div>
								<div className="orientation_text">
									<p>
										<ul className="mx-auto max-width-350px has-text-left my-4">
											<li className="has-text-weight-bold mb-2">
												- {i18n.t("PlanBlock_TechOrdaSysText1")}
											</li>
											<li className="has-text-weight-bold mb-2">
												- {i18n.t("PlanBlock_TechOrdaSysText2")}
											</li>
											<li className="has-text-weight-bold mb-2">
												- {i18n.t("PlanBlock_TechOrdaSysText3")}
											</li>
											<li className="has-text-weight-bold mb-2">
												- {i18n.t("PlanBlock_TechOrdaSysText4")}
											</li>
										</ul>
										<div className="is-align-items-center line-hor-75 has-background-black my-2"></div>
										{i18n.t("PlanBlock_TechOrdaSysTextF")}
										<br />
										<div className="max-width-350px mx-auto has-text-left has-text-weight-bold">
											{i18n.t("Price")}: 600 000 тг
										</div>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default withTranslation()(Plan);
