import Flickity from "react-flickity-component";
import i1 from "../../../assets/img/image4.jpg";
import i2 from "../../../assets/img/ADN_4662.jpg";
import i3 from "../../../assets/img/ADN_7485.jpg";
import i4 from "../../../assets/img/alem_fun.jpg";
import i5 from "../../../assets/img/ADN_4684.jpg";
import { withTranslation } from "react-i18next";
// import i18n from "../../../i18n/i18n";

function Carousel() {
	return (
		<>
			<section className="section">
				<div className="container">
					<Flickity
						percentPosition={false}
						imagesLoaded={true}
						fullscreen={true}
						lazyLoad={1}
					>
						<img
							alt="img"
							src={i1}
							className="image custom-is-height-200px-mobile custom-is-height-400px-tablet mx-3"
						/>
						<img
							alt="img"
							src={i2}
							className="image custom-is-height-200px-mobile custom-is-height-400px-tablet mx-3"
						/>
						<img
							alt="img"
							src={i3}
							className="image custom-is-height-200px-mobile custom-is-height-400px-tablet mx-3"
						/>
						<img
							alt="img"
							src={i4}
							className="image custom-is-height-200px-mobile custom-is-height-400px-tablet mx-3"
						/>
						<img
							alt="img"
							src={i5}
							className="image custom-is-height-200px-mobile custom-is-height-400px-tablet mx-3"
						/>
					</Flickity>
				</div>
			</section>
		</>
	);
}

export default withTranslation()(Carousel);
