import "../../style/css/page-faq.css";
import React, { useEffect, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { questions, questionskz } from "../../utils/consts";

function Faq() {
  const { t, i18n } = useTranslation();
  const [isChecked, setIsChecked] = useState(true);
  const [currentQuestions, setCurrentQuestions] = useState([]);

  useEffect(() => {
    setCurrentQuestions(i18n.language === "kz" ? questionskz : questions);
  }, [i18n.language]);

  const addQuestions = () => {
    const faq = document.getElementById("section-faq");
    faq.innerHTML = ""; // Clear previous questions
    for (let i = 0; i < currentQuestions.length; i++) {
      const question = currentQuestions[i];
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `
        <div class="question-block mb-5">
            <input id="q-${i + 1}" class="is-hidden" type="checkbox">
            <label for="q-${
              i + 1
            }" class="is-size-6 has-text-weight-bold is-clickable mb-4 is-flex is-align-items-center"><div class="line"></div>${
        question.question
      }</label>
            <article>
                <p class="p-4">${question.answer}</p>
            </article>
        </div>`;
      faq.appendChild(wrapper);
    }
  };

  useEffect(() => {
    addQuestions();
  }, [currentQuestions]);

  const check = () => {
    setIsChecked(!isChecked);
    const list = document.getElementsByTagName("input");
    document.getElementById("btn_Questions").innerHTML = isChecked
      ? t("close_all_answers")
      : t("open_all_answers");
    for (let i = 0; i < list.length; i++) {
      list[i].checked = isChecked;
    }
  };

  return (
    <>
      <section className="section mt-6">
        <div className="container">
          <section id="section-faq" className="pt-4">
            <div className="button is-primary mb-5" onClick={() => check()}>
              <span id="btn_Questions">{t("open_all_answers")}</span>
            </div>
          </section>
        </div>
      </section>
    </>
  );
}

export default withTranslation()(Faq);
