import React from "react";
import i18n from "../../../i18n/i18n";
import icon365 from "../../../assets/lordicons/45-clock-time-outline.png";
import iconCmd from "../../../assets/lordicons/743-multimedia-code-2-outline.png";
import iconEnv from "../../../assets/lordicons/2329-e-fuels-outline.png";
import iconGame from "../../../assets/lordicons/476-controller-pad-game-play-outline.png";
// Icons
import iconP2P from "../../../assets/lordicons/1004-management-team-outline.png";
import iconPuzzle from "../../../assets/lordicons/957-team-work-outline.png";
import { withTranslation } from "react-i18next";

function HowItWorks() {
	return (
		<>
			<section className="section has-background-black has-text-white">
				<div className="container py-6">
					<div className="mb-6 has-text-right">
						<h2 className="title has-text-white">
							<span className="line-above">02</span>
						</h2>
						<h3 className="subtitle is-3 has-text-white">
							{i18n.t("HowItWorks")}
						</h3>
					</div>
					<div className="columns">
						<div className="column has-text-centered">
							<div className="is-flex is-justify-content-center">
								<img alt="Puzzle" src={iconP2P} className="image is-128x128" />
							</div>
							<h4 className="has-text-weight-bold">
								{i18n.t("HIW_NoTeachersTitle")}
							</h4>
							<p>{i18n.t("HIW_NoTeachersText")}</p>
						</div>
						<div className="column has-text-centered">
							<div className="is-flex is-justify-content-center">
								<img
									alt="Puzzle"
									src={iconPuzzle}
									className="image is-128x128"
								/>
							</div>
							<h4 className="has-text-weight-bold">
								{i18n.t("HIW_ProblemSolvingTitle")}
							</h4>
							<p>{i18n.t("HIW_ProblemSolvingText")}</p>
						</div>
						<div className="column has-text-centered">
							<div className="is-flex is-justify-content-center">
								<img alt="Puzzle" src={iconCmd} className="image is-128x128" />
							</div>
							<h4 className="has-text-weight-bold">
								{i18n.t("HIW_PracticeTitle")}
							</h4>
							<p>{i18n.t("HIW_PracticeText")}</p>
						</div>
					</div>
					<div className="columns">
						<div className="column has-text-centered">
							<div className="is-flex is-justify-content-center">
								<img alt="Puzzle" src={icon365} className="image is-128x128" />
							</div>
							<h4 className="has-text-weight-bold">
								{i18n.t("HIW_AlemWorksTitle")}
							</h4>
							<p>{i18n.t("HIW_AlemWorksText")}</p>
						</div>
						<div className="column has-text-centered">
							<div className="is-flex is-justify-content-center">
								<img alt="Puzzle" src={iconEnv} className="image is-128x128" />
							</div>
							<h4 className="has-text-weight-bold">
								{i18n.t("HIW_EnvironmentTitle")}
							</h4>
							<p>{i18n.t("HIW_EnvironmentText")}</p>
						</div>
						<div className="column has-text-centered">
							<div className="is-flex is-justify-content-center">
								<img alt="Puzzle" src={iconGame} className="image is-128x128" />
							</div>
							<h4 className="has-text-weight-bold">
								{i18n.t("HIW_GamificationTitle")}
							</h4>
							<p>{i18n.t("HIW_GamificationText")}</p>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
export default withTranslation()(HowItWorks);
