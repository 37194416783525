import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locales/en/translation.json";
import translationRU from "./locales/ru/translation.json";
import translationKZ from "./locales/kz/translation.json";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';

const resources = {
    en: {
        translation: translationEN,
    },
    ru: {
        translation: translationRU,
    },
    kz: {
        translation: translationKZ,
    },
    // 
};

i18n.use(initReactI18next).use(Backend).use(LanguageDetector).init({
    resources,
    fallbackLng: "en", // use en if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
        escapeValue: false, // react already safes from xss
    },
    load: "languageOnly",
    nonExplicitSupportedLngs: false,
    // lng: "en",
    localeExtension: "json",
    localePath: "locales",
});



export default i18n;
