import "i18next";

import React from "react";
import history from "../../utils/history";
import i18n from "../../i18n/i18n";
import logo from "../../assets/img/logo_white.svg";
import logoTg from "../../assets/img/logo3d-telegram.png";
import { withTranslation } from "react-i18next";

export function Navigation() {
	const [isActive, setisActive] = React.useState(false);
	const languages = ["ru", "en", "kz"];
	const handleChange = (event) => {	
		i18n.changeLanguage(event);
	};

	const RedirectTo = (endpoint) => {
		history.push(endpoint);
		setisActive(!isActive);
		window.scrollTo({ top: 0 });
	};

	return (
		<nav
			className="navbar is-fixed-top is-black"
			role="navigation"
			aria-label="main navigation"
		>
			<div className="container">
				<div className="navbar-brand my-4 mr-4">
					<a className="navbar-item" href="/">
						<img alt="logo" src={logo} width="112" height="28" />
					</a>
					<div
						onClick={() => {
							setisActive(!isActive);
						}}
						role="button"
						className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
						aria-label="menu"
						aria-expanded="false"
						data-target="navbarBasicExample"
					>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
						<span aria-hidden="true"></span>
					</div>
				</div>

				<div
					id="navbarBasicExample"
					className={`navbar-menu ${isActive ? "is-active" : ""}`}
				>
					<div className="navbar-start">
						<a onClick={() => RedirectTo("/")} className="navbar-item">
							{i18n.t("Главная")}
						</a>

						<a onClick={() => RedirectTo("/faq")} className="navbar-item">
							FAQ
						</a>

						{/* <a href="https://blog.alem.school" className="navbar-item">
							{i18n.t("Блог")}
						</a> */}

						{/* <a onClick={() => RedirectTo("/partners")} className="navbar-item">
              {i18n.t("Partners")}
            </a> */}
					</div>

					<div className="navbar-end">
						<div className="navbar-item is-flex">
							{languages.map((lan, idx) => (
								<a
									className="has-text-grey p-2 is-clickable"
									key={idx}
									onClick={() => handleChange(lan)}
								>
									{" "}
									{lan}{" "}
								</a>
							))}
						</div>
						<div className="navbar-item">
							<div className="buttons">
								{/* <a href="https://cup.alem.school" className="button is-primary">
                  <strong>alem cup</strong> 
                </a> */}
								<a
									href="https://forms.office.com/Pages/ResponsePage.aspx?id=UI3UNgBIOkCSIkT2ccij5OTU12ZmScFCtWvLzqM2KcNUOVVKVFdIODRHUVJLSUFZWDE3UEI2MFhDTS4u"
									className="button is-primary"
									target="_blank"
									rel="noreferrer"
								>
									{i18n.t("excursion")}
								</a>
								<button
									className="js-modal-trigger button is-primary"
									data-target="modal-ask-qustion"
								>
									<img alt="tg-icon" src={logoTg} className="icon" />
									<p>{i18n.t("BtnAskQuestion")}</p>
								</button>
								<a
									href="https://platform.alem.school"
									className="button is-primary is-outlined has-text-white is-link has-background-black"
								>
									{i18n.t("Поступить")}
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>
	);
}
export default withTranslation()(Navigation);
