import React from "react";
import i01 from "../../../assets/img/logo-01Edu-Darkbg-vertical.svg";
import i18n from "../../../i18n/i18n";
import i9 from "../../../assets/img/astana-hub.png";
import { withTranslation } from "react-i18next";

function Partners() {
	return (
		<section className="section has-background-black has-text-white">
			<div className="container">
				<div className="columns is-vcentered">
					<div className="column is-one-quarter">
						<h3 className="subtitle is-3 has-text-white has-text-weight-bold">
							<span className="line-above">{i18n.t("партнеры")}</span>
						</h3>
					</div>
					<div className="column is-flex is-justify-content-flex-end">
						{/* <a
							title="01-edu"
							href="https://01-edu.org"
							target="_blank"
							rel="noreferrer"
							className="mr-2"
						>
							<img alt="01-edu.org" src={i01} className="image is-160x160" />
						</a> */}
						<a
							title="astana-hub"
							href="https://astanahub.com"
							target="_blank"
							rel="noreferrer"
						>
							<img alt="astanahub.com" src={i9} className="image is-160x160" />
						</a>
					</div>
				</div>
			</div>
		</section>
	);
}

export default withTranslation()(Partners);
