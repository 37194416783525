import { ReactComponent as PhotoGraph } from "../../../assets/img/Eng_svg.svg";
import { ReactComponent as PhotoGraphRu } from "../../../assets/img/Ru_svg.svg";
import React from "react";
import '../../../style/css/graph.css'
import i18n from "../../../i18n/i18n";
import { withTranslation } from "react-i18next";

function Graph({ t }) {
    const language = i18n.language;
    const PhotoComponent = language === "ru" ? PhotoGraphRu : PhotoGraph;

    return (
        <section className="section has-background-white">
            <div className="container">
                <div className="has-text-right">
                    <h3 className="subtitle is-3 has-text-black">
                        <span className="line-above">{t("GraphBlock_Title")}</span>
                    </h3>
                </div>
                <div className="py-2">
                    <PhotoComponent className="full-size-svg" />
                </div>
            </div>
        </section>
    );
}

export default withTranslation()(Graph);
