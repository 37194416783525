import React from "react";
import i18n from "../../../i18n/i18n";
import iconarrow from "../../../assets/lordicons/31-arrow-right-outline-black.png";
import { withTranslation } from "react-i18next";

function Plan() {
	return (
		<section className="section">
			<div className="container py-6">
				<div className="mb-6">
					<h2 className="title">
						<span className="line-above">03</span>
					</h2>
					<h3 className="subtitle is-3">{i18n.t("PlanBlock_Title")}</h3>
				</div>
				<div className="mb-6">
					<h4 className="subtitle is-4">
						<p>{i18n.t("PlanBlock_ModulesTitle")}</p>
					</h4>
					<div className="columns">
						<div className="column is-one-quarter">
							<div className="orentation">
								<div className="orientation_title">Go</div>
								<div className="orientation_text">
									<p>{i18n.t("PlanBlock_GoText")}</p>
								</div>
							</div>
						</div>
						<div className="column">
							<div className="plan-icon-arrow">
								<img alt="PlanIcon" src={iconarrow} className="icon is-large" />
							</div>
						</div>
						<div className="column is-one-quarter">
							<div className="orentation">
								<div className="orientation_title">JavaScript</div>
								<div className="orientation_text">
									<p>{i18n.t("PlanBlock_JSText")}</p>
								</div>
							</div>
						</div>
						<div className="column">
							<div className="plan-icon-arrow">
								<img alt="PlanIcon" src={iconarrow} className="icon is-large" />
							</div>
						</div>
						<div className="column is-one-quarter">
							<div className="orentation">
								<div className="orientation_title">Rust</div>
								<div className="orientation_text">
									<p>{i18n.t("PlanBlock_RustText")}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="pb-4">
					<h4 className="subtitle is-4">
						<p>{i18n.t("PlanBlock_BranchesTitle")}</p>
					</h4>
					<div className="columns columns-wrap">
						<div className="column">
							<div className="orentation">
								<div className="orientation_title">Data-science &amp; AI</div>
								<div className="orientation_text">
									<p>{i18n.t("PlanBlock_DataScienceText")}</p>
								</div>
							</div>
						</div>
						<div className="column">
							<div className="orentation">
								<div className="orientation_title">Videogames &amp; AR</div>
								<div className="orientation_text">
									<p>{i18n.t("PlanBlock_VideogamesText")}</p>
								</div>
							</div>
						</div>
						<div className="column">
							<div className="orentation">
								<div className="orientation_title">Mobile development</div>
								<div className="orientation_text">
									<p>{i18n.t("PlanBlock_MobileText")}</p>
								</div>
							</div>
						</div>
						<div className="column">
							<div className="orentation">
								<div className="orientation_title">
									DevOps &amp; Cybersecurity
								</div>
								<div className="orientation_text">
									<p>{i18n.t("PlanBlock_DevOpsCyberSecText")}</p>
								</div>
							</div>
						</div>
						<div className="column">
							<div className="orientation_title">Blockchain</div>
							<div className="orientation_text">
								<p>{i18n.t("PlanBlock_BlockchainText")}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default withTranslation()(Plan);
