import React from "react";
import i18n from "../../../i18n/i18n";
import logoAlem from "../../../assets/img/logo-alem-lite-black.svg";
import { withTranslation } from "react-i18next";

function About() {
	return (
		<>
			<section className="section">
				<div className="container py-6">
					<h2 className="title">
						<span className="line-above">01</span>
					</h2>
					<h3 className="subtitle is-3">{i18n.t("AboutBlock_Title")}</h3>
					<div className="columns is-vcentered">
						<div className="column is-one-third is-hidden-mobile">
							<img alt="alem" src={logoAlem} />
						</div>
						<div className="column">
							<p className="is-size-5-tablet is-size-4-desktop">
								{i18n.t("AboutBlock_Text1")}
							</p>
							<br></br>
							<p className="is-size-5-tablet is-size-4-desktop">
								{i18n.t("AboutBlock_Text2")}
							</p>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default withTranslation()(About);
