import { Route, Router, Switch } from "react-router-dom";

import Faq from "../components/page-FAQ/FAQ";
import Main from "../components/page-main/main";
import React from "react";
import TechOrda from "../components/page-techorda/techorda";
import history from "../utils/history";

// import Partners from "../components/page-partners/partners";

export default function Routes() {
	return (
		<Router history={history}>
			<Switch>
				<Route path="/faq" exact component={Faq} />
				{/* <Route path="/techorda" exact component={TechOrda} /> */}
				<Route path="/" component={Main} />
				{/* <Route path="/partners" exact component={Partners} /> */}
			</Switch>
		</Router>
	);
}
